import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
} from "@angular/core";
import { AplicativosService } from "../services/aplicativos.service";

@Directive({
  selector: ".auth-pages",
})
export class AuthPagesDirective implements AfterViewInit {
  constructor(
    public aplicativosService: AplicativosService,
    public elementRef: ElementRef<HTMLElement>
  ) {
    this.aplicativosService.clearItem();
    this.aplicativosService.clearTheme();
  }

  ngAfterViewInit() {
    if (this.aplicativosService.appType === "ipe-saude" || this.aplicativosService.appType === "digital-life") {
      const logosWrapper = document.createElement("div");
      logosWrapper.classList.add("logos-wrapper");

      const logoSergioLovato = document.createElement("img");
      logoSergioLovato.src = '/assets/imgs/sergio-lovato.png'
      logoSergioLovato.classList.add("sergio-lovato");

      const logoIpe = document.createElement("img");
      logoIpe.src = '/assets/imgs/ipe.png'
      logoIpe.classList.add("ipe-saude");

      const logoEasy = document.createElement("img");
      logoEasy.src = '/assets/imgs/logo-easy-sem-corporate.png'
      logoEasy.classList.add("ipe-saude");

      const logoTioJoao = document.createElement("img");
      logoTioJoao.src = '/assets/imgs/tio-joao.png'
      logoTioJoao.classList.add("ipe-saude");
      
      if(this.aplicativosService.appType === "ipe-saude"){
        logosWrapper.append(logoIpe);
      }

      if(this.aplicativosService.appType === "digital-life"){      
        logosWrapper.append(logoTioJoao);
        logosWrapper.append(logoEasy);
      }

      this.elementRef.nativeElement.append(logosWrapper);
    }

  }

  @HostBinding("class.auth-pages-digital-life") get appTypePazEBem() {
    return this.aplicativosService.appType === "digital-life";
  }
  
  @HostBinding("class.auth-pages-mundo-verde") get appTypeMundoVerde() {
    return this.aplicativosService.appType === "mundo-verde";
  }

  @HostBinding("class.auth-pages-ipe-saude") get appTypeIpeSaude() {
    return this.aplicativosService.appType === "ipe-saude";
  }
  @HostBinding("class.auth-pages-personal") get appTypePersonal() {
    return this.aplicativosService.appType === "personal";
  }
  @HostBinding("class.AuthPagesDirective") directiveHint = true;
}
