import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AlertController, NavController } from "@ionic/angular";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

type AppType = "corporate" | "personal" | "ipe-saude" | "mundo-verde" | "digital-life";

/*
  Generated class for the AplicativosService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class AplicativosService {
  // @todo importante sempre mudar no tempo de compilação e onde for publicar pois é a partir daqui q é setado as coisas de cada tipo de aplicativo
  appType: AppType = "digital-life";
  item: any;
  list: any[] = [];
  userIsIpeSaude = false;
  constructor(
    public apiService: ApiService,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public authService: AuthService,
    public alertCtrl: AlertController,
    public navCtrl: NavController
  ) {
    this.apiService.saveOneSignal();
    this.splashScreen.hide();

    try {
      const storageList = JSON.parse(
        window.localStorage.getItem("aplicativos_list")
      );
      const storageItem = JSON.parse(
        window.localStorage.getItem("aplicativos_item")
      );
      if (storageList) {
        this.list = storageList;
      }
      if (storageItem) {
        this.setItem(storageItem);
      }
    } catch {
      console.error("catch localstorage");

      this.item = {};
      this.list = [];
    }
  }

  public getAll() {
    return new Promise((resolve, reject) =>
      this.apiService.get("/aplicativos2").subscribe(
        (r: any[]) => {
          window.localStorage.setItem("aplicativos_list", JSON.stringify(r));
          resolve((this.list = r));
        },
        (e) => reject(e)
      )
    );
  }

  setItem(item) {
    this.item = item;
    window.localStorage.setItem("aplicativos_item", JSON.stringify(item));

    this.setTheme(item);
  }
  setTheme(item) {
    this.statusBar.backgroundColorByHexString(item.cor);

    // @TODO USAR VARIAVEIS DO IONIC CSS3 PARA ALTERAR CORES INVEZ DO SCSS
    document.body.setAttribute("class", "");
    if (item.id == 1) {
      document.body.classList.add("theme-easymind");
    } else if (item.id == 2) {
      document.body.classList.add("theme-easyfit-sports");
    } else if (item.id == 3) {
      document.body.classList.add("theme-nutri");
    } else if (item.id == 4) {
      document.body.classList.add("theme-easylife");
    } else if (item.id == 5) {
      document.body.classList.add("theme-easylife");
      document.body.classList.add("theme-easyfit-gestao");
    } else if (item.id == 6) {
      document.body.classList.add("theme-digital-life");
      document.body.classList.add("theme-digital-life-central");
    } else if (item.id == 7) {
      console.log("setou 7");
      document.body.classList.add("theme-easylife-gastronomia");
    } else if (item.id == 9) {
      document.body.classList.add("theme-digital-life");
      document.body.classList.add("theme-digital-life-central");
    }
  }
  clearTheme() {
    document.body.setAttribute("class", "");
  }
  clearItem() {
    this.item = {};
  }

  getNutri() {
    return this.list.find((obj) => obj.id == 3);
  }
  getEasyfitLife() {
    return this.list.find((obj) => obj.id == 4);
  }

  getInitialPage(item) {
    if (item.id === 1) {
      return "/easymind-tabs";
    }
    if (item.id === 3) {
      // cadastro de kg/altura e outros dados do usuário removido para ter mais simplicidade
      // if (!this.authService.user.user.inicio_kg) {
      //   return "/cadastro-usuario";
      // }
      return "/nutri-tabs";
    }
    if (item.id === 5) {
      return "/gestao-tabs";
    }
    if (item.id === 6) {
      return "/central-tabs";
    }
    return "/default-tabs";
  }

  async loginIpeSaude() {
    if (!this.authService.isLoggedIn()) {
      console.error("user not logged in");
      throw new Error("Você não está logado");
    }
    if (!this.userIsIpeSaude) {
      return;
    }
    return this.apiService.post("/login/ipe-saude").toPromise();
  }
  async askIpeSaudeHasAccount() {
    const alert = await this.alertCtrl.create({
      header: "Você já possui cadastro na plataforma Easylife?",
      backdropDismiss: false,
      buttons: [
        {
          text: "Não",
          handler: () => this.navCtrl.navigateRoot("/faca-parte"),
        },
        {
          text: "Sim",
          handler: () => this.navCtrl.navigateRoot("/login"),
        },
      ],
    });
    alert.present();
  }
  async askIpeSaude() {
    if (this.appType !== "ipe-saude") {
      return;
    }
    const isPremium = await this.authService.isPremiumAsync();
    if (isPremium) {
      return;
    }
    if (this.userIsIpeSaude) {
      return;
    }
    const alert = await this.alertCtrl.create({
      header: "Você é usuário(a) do IPE Saúde?",
      backdropDismiss: false,
      buttons: [
        {
          text: "Não",
          role: "cancel",
          handler: () => {
            this.askIpeSaudeCancel();
          },
        },
        {
          text: "Sim",
          handler: async () => {
            this.userIsIpeSaude = true;
            if (this.authService.isLoggedIn()) {
              this.loginIpeSaude();
              this.navCtrl.navigateRoot("/aplicativos");
            } else {
              this.askIpeSaudeHasAccount();
            }
          },
        },
      ],
    });
    alert.present();
  }
  
  async askPazEBem(){
    if (this.authService.isLoggedIn()) {
      this.navCtrl.navigateRoot("/aplicativos");
    }
    return;
  }

  async askIpeSaudeCancel() {
    const alert = await this.alertCtrl.create({
      header: "IPE Saúde",
      message:
        "Esta plataforma é exclusiva para usuários do IPE Saúde. Para conhecer as demais plataformas da Easylife acesse:",
      backdropDismiss: false,
      buttons: [
        {
          text: "Acessar",
          handler: () => {
            if (Capacitor.isNative) {
              Capacitor.Plugins.App.exitApp();
            } else {
              window.location.href = "https://www.easylifecorporate.com.br";
            }
          },
        },
      ],
    });
    alert.present();
  }

  /**
   * Redireciona para a página ou ação necessária para se tornar premium
   */
  async pageToBePremium() {
    if (this.appType === "personal") {
      this.navCtrl.navigateRoot("/contratar");
    } else if (this.appType === "corporate"){
      this.navCtrl.navigateRoot("/login-codigo");
    } else if (this.appType === "ipe-saude") {
      this.askIpeSaude();
    } else if (this.appType === "digital-life") {
      this.navCtrl.navigateRoot("/login-codigo");
    }
  }
}
