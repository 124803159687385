import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Capacitor } from "@capacitor/core";
// import {
//   IAPProduct,
//   InAppPurchase2,
// } from "@ionic-native/in-app-purchase-2/ngx";
import {
  ActionSheetController,
  AlertController,
  NavController,
} from "@ionic/angular";
import { AplicativosService } from "src/app/services/aplicativos.service";
import { PlanosService } from "src/app/services/planos.service";

const PRODUTOS = {
  PLANO_MENSAL: "plano_mensal",
  PLANO_ANUAL: "plano_anual",
};

// todo: deletar isso aqui quando não precisar mais
interface mockProduct {
  id: string;
  title: string;
  price: string;
  canPurchase: boolean;
  billingPeriodUnit: string;
}
@Component({
  selector: "page-contratar",
  templateUrl: "./contratar.html",
  styleUrls: ["./contratar.scss"],
})
export class ContratarPage implements OnInit {
  showMore = false;

  // TODO: cadastrar nas lojas
  productIds = Object.values(PRODUTOS).map((p) => ({
    id: p,
    // type: this.store.PAID_SUBSCRIPTION,
  }));

  // products: IAPProduct[] = [];
  mockProducts: mockProduct[] = [
    {
      id: "plano_mensal",
      title: "Plano Mensal",
      price: "R$ 123.45",
      canPurchase: true,
      billingPeriodUnit: "mês",
    },
    {
      id: "plano_anual",
      title: "Plano Anual",
      price: "R$ 1450.00",
      canPurchase: true,
      billingPeriodUnit: "ano",
    },
  ];

  constructor(
    public planosService: PlanosService,
    public aplicativosService: AplicativosService,
    public navCtrl: NavController,
    // private store: InAppPurchase2,
    private ref: ChangeDetectorRef,
    public actionSheetCtrl: ActionSheetController,
    public alertCtrl: AlertController
  ) {
    this.aplicativosService.clearItem();
    this.aplicativosService.clearTheme();
  }

  ngOnInit() {
    // if (Capacitor.isNative) {
    //   this.store.verbosity = this.store.DEBUG;
    //   this.registerProducts();
    //   this.setupListeners();
    //   this.store.ready(() => {
    //     this.products = this.store.products;
    //     this.ref.detectChanges();
    //   });
    // } else {
    //   console.error('Not native cant perform init functions')
    // }
  }

  euQuero() {
    if (Capacitor.isNative) {
      // app: manda pra tela de compra da loja
      this.presentPurchaseSheet();
    } else {
      // web: manda pra tela de pagamento com cartão
      this.navCtrl.navigateForward("/contratar-pagar");
    }
  }

  // todo: puxar da app store (apesar de ter na API, pode dar ruim com as lojas for fixo)
  async presentPurchaseSheet() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: "Planos",
      buttons: [
        ...this.mockProducts
          .filter((p) => p.canPurchase)
          .map((p) => ({
            text: `${p.title} - ${p.price}/${p.billingPeriodUnit}`,
            // handler: () => this.purchase(p as unknown as IAPProduct), // todo: esse cast não vai mais ser necessário qnd o prooduto vier da store
          })),
        {
          text: "Agora não",
          role: "cancel",
          handler: () => this.navCtrl.navigateForward("/aplicativos"),
        },
      ],
    });
    await actionSheet.present();
  }

  async presentErrorAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: "Ops! Houve um problema",
      message: `${msg}`,
      buttons: [
        {
          text: "Tentar novamente",
          handler: () => this.presentPurchaseSheet(),
        },
        {
          text: "Cancelar",
          role: "cancel",
          handler: () => this.navCtrl.navigateForward("/aplicativos"),
        },
      ],
    });
    await alert.present();
  }

  registerProducts() {
    if (!Capacitor.isNative) {
      console.error("Not native cant registerProducts()");
      return;
    }
    // this.store.register(this.productIds);
    // this.store.refresh();
  }

  setupListeners() {
    if (!Capacitor.isNative) {
      console.error("Not native cant setupListeners()");
      return;
    }
    // this.store
    //   .when("product")
    //   .approved((product: IAPProduct) => {
    //     // todo: agora é premium, mandar pra api o objeto product

    //     this.ref.detectChanges();

    //     product.verify();
    //   })
    //   .verified((product: IAPProduct) => product.finish());

    // this.store.when(PRODUTOS.PLANO_ANUAL).owned((product: IAPProduct) => {
    //   // todo: agora é premium
    // });
    // this.store.when(PRODUTOS.PLANO_MENSAL).owned((product: IAPProduct) => {
    //   // todo: agora é premium
    // });
  }

  // purchase(product: IAPProduct) {
  //   if (!Capacitor.isNative) {
  //     console.error("Not native cant purchase()");
  //     return;
  //   }
  //   this.store.order(product).then(
  //     (p) => {
  //       // compra em progresso, n precisa fazer nada aqui
  //     },
  //     (e) => this.presentErrorAlert(e?.message || "Erro ao concluir a compra") // todo: confirmar se realmente vem assim
  //   );
  // }

  // // regras da app store
  // restore() {
  //   if (!Capacitor.isNative) {
  //     console.error("Not native cant restore()");
  //     return;
  //   }
  //   this.store.refresh();
  // }
}
