import {
  HttpClient,


  HttpErrorResponse, HttpParams, HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  FileTransfer,

  FileTransferObject, FileUploadOptions
} from "@ionic-native/file-transfer/ngx";
// import { OneSignal } from "@ionic-native/onesignal/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { AlertController, NavController, Platform } from "@ionic/angular";
import { Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { GlobalService } from "./global.service";

// import { LoginPage } from '../../pages/login/login';
// import { LoginPage } from '../../pages/login/login';

/*
  Generated class for the ApiService provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: "root",
})
export class ApiService {
  uploadProgress = new Subject<number>();
  _expiredSession;
  constructor(
    public http: HttpClient,
    public auth: AuthService,
    public global: GlobalService,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    public fileTransfer: FileTransfer,
    public plt: Platform,
    public splashScreen: SplashScreen,
    // public oneSignal: OneSignal
  ) {
    console.log("API PROVIDER HAS STARTED");
  }

  get(url: string) {
    return this.http
      .get(this.global.apiUrl + url, {
        headers: this.auth.getAuthorizationHeader(),
      })
      .pipe(catchError((err) => this.handleError(err)));
  }
  postAsFormData(
    url: string,
    body: Object,
    files?: Array<{ name: string; fileList: FileList; multiple?: boolean }>,
    options = {
      method: "POST",
      httpParams: new HttpParams(),
    }
  ) {
    const formData = new FormData();
    // Coloca todo body no form data.., aproveita e da trim nas strings
    // provavelmente da pra fazer uma função recursiva só pra tratar os dados que são passadas para FormData
    Object.keys(body).forEach((k) => {
      if (typeof body[k] === "string") {
        body[k] = body[k].trim();
      }
      if (Array.isArray(body[k])) {
        body[k].forEach((val) => {
          formData.append(k + "[]", val);
        });
      } else if (typeof body[k] === "object" && body[k] !== null) {
        Object.keys(body[k]).forEach((l) => {
          formData.append(k + "[" + l + "]", body[k][l]);
        });
      } else if (body[k] === null) {
        formData.append(k, "");
      } else {
        formData.append(k, body[k]);
      }
    });

    // Coloca todos files no FormData
    files.forEach((obj) => {
      obj.multiple = obj.multiple ? obj.multiple : false;
      if (!obj.fileList) {
        return;
      }
      formData.delete(obj.name);
      Array.from(obj.fileList).forEach((file, i) => {
        let name = obj.name;
        if (obj.multiple) {
          name = obj.name + "[" + i + "]";
        }
        formData.append(name, file);
      });
    });

    const req = new HttpRequest(
      options.method,
      this.global.apiUrl + url,
      formData,
      {
        params: options.httpParams,
        reportProgress: true,
        headers: this.auth.getAuthorizationHeader(),
      }
    );
    return this.http
      .request(req)
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  post(url: string, body = {}, handleError = true) {
    if (handleError) {
      return this.http
        .post(this.global.apiUrl + url, body, {
          headers: this.auth.getAuthorizationHeader(),
        })
        .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
    } else {
      return this.http
        .post(this.global.apiUrl + url, body, {
          headers: this.auth.getAuthorizationHeader(),
        })
        .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
    }
  }
  patch(url: string, body?) {
    return this.http
      .patch(this.global.apiUrl + url, body, {
        headers: this.auth.getAuthorizationHeader(),
      })
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }
  put(url: string, body?) {
    return this.patch(url, body);
  }
  delete(url: string, params?) {
    return this.http
      .delete(this.global.apiUrl + url, {
        headers: this.auth.getAuthorizationHeader(),
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err)));
  }

  async alertHandleError({ header, message, cssClass }) {
    (await this.alertCtrl.create({ header, message, cssClass })).present();
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status === 401) {
        this.expiredSession();
      } else if (error.status === 403) {
        this.alertHandleError({
          header: "Permissão negada",
          message: "Infelizmente você não tem permissão para fazer isso",
          cssClass: "text-danger",
        });
      } else if (error.status === 404) {
        this.alertHandleError({
          header: "Ops...",
          message:
            error.error.message ||
            "O que você estava procurando não foi encontrado",
          cssClass: "text-danger",
        });
      } else if (error.status === 400 || error.status === 422) {
        this.alertHandleError({
          header: "Algo errado aconteceu",
          message: error.error.message,
          cssClass: "text-danger",
        });
      } else if (error.status === 500) {
        // const alert = this.alertCtrl.create({
        //   title: 'Erro interno',
        //   message: 'Por favor tente novamente',
        //   cssClass: 'text-danger'
        // });
        // alert.present();
      } else {
        // const alert = this.alertCtrl.create({
        //   title: 'Erro desconhecido',
        //   message: 'Por favor tente novamente',
        //   cssClass: 'text-danger'
        // });
        // alert.present();
      }
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  async expiredSession() {
    if (this._expiredSession) {
      this._expiredSession.present();
      return;
    }
    this._expiredSession = await this.alertCtrl.create({
      header: "Sessão expirada",
      message:
        "Você precisa logar novamente para continuar usando o aplicativo",
      cssClass: "text-danger",
      buttons: [
        {
          text: "OK",
          handler: () => {
            this.navCtrl.navigateRoot("/login").then(() => this.auth.logout());
          },
          role: "cancel",
        },
      ],
    });
    this._expiredSession.present();
  }

  saveOneSignal() {
    if (this.auth.player_id.length === 0) {
      console.error("this.auth.player_id está em branco");
      console.error("Trying to get onesignal now");
      // this.oneSignal.getIds().then((r: any) => {
      //   console.log("oneSingal getIds:", JSON.stringify(r));
      //   this.auth.player_id = r.userId;
      //   this.saveOneSignal();
      // });
      return;
    }
    if (this.auth.isLoggedIn()) {
      this.post("/users/" + this.auth.user.user.id + "/onesignal", {
        player_id: this.auth.player_id,
      }).subscribe();
    } else {
      console.error(
        "User is not logged in, onesignal could not be saved, it was stored on this.auth.player_id"
      );
    }
  }

  uploadFiles(file, url, options?: FileUploadOptions) {
    file = this.plt.is("ios") ? file.replace(/^file:\/\//, "") : file;

    const defaultOptions: FileUploadOptions = {
      fileKey: "thumbnail_principal",
      fileName: file.split("/").pop(),
      chunkedMode: false,
      headers: { Authorization: this.auth.token },
    };
    Object.assign(defaultOptions, options);

    const fileTransfer: FileTransferObject = this.fileTransfer.create();

    fileTransfer.onProgress((progressEvent: ProgressEvent) => {
      let progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      if (isNaN(progress)) {
        progress = 100;
      }
      this.uploadProgress.next(progress);
    });
    return fileTransfer.upload(file, this.global.apiUrl + url, defaultOptions);
  }

  postFile(fileToUpload: File, url: string) {
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);

    const options = {
      method: "POST",
      httpParams: new HttpParams(),
    };

    const req = new HttpRequest(
      options.method,
      this.global.apiUrl + url,
      formData,
      {
        params: options.httpParams,
        reportProgress: true,
        headers: this.auth.getAuthorizationHeader(),
      }
    );

    return this.http.request(req).pipe(catchError((e) => this.handleError(e)));
  }
}
