import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
}
    from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var code = window.localStorage.getItem("code_id");
        request = request.clone({
            setHeaders: {
                AppType: "digital-life",
                Code_id: code ? code : ""
            }
        });

        console.log(request);
        return next.handle(request);
    }
}